<script>
import appConfig from "@/app.config";
import { http } from "../../../helpers/easyindustriaapi/config";
import { authEasyMethods, notificationMethods } from "@/state/helpers";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Detail from "./detail";
import Edit from "./edit.vue";
import Insert from "./insert.vue";

console.clear();

export default {
  page: {
    title: "Empresas",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Detail, Edit, Insert },
  data() {
    return {
      currentUser: null,
      currentEmpresa: null,
      empresas: [],
      titleBody: "Listagem das Empresas",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Empresas",
          href: "/empresas",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        {
          label: "ID",
          key: "id",
          sortable: true,
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          label: "Apelido",
          key: "apelido",
          sortable: true,
          thClass: "text-center",
        },
        {
          label: "CNPJ",
          key: "cnpj",
          sortable: true,
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          label: "I. E.",
          key: "ie",
          sortable: false,
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          label: "Última NF-e",
          key: "ultianfe",
          sortable: false,
          tdClass: "text-right",
          thClass: "text-center",
        },
        {
          label: "Ações",
          key: "acoes",
          sortable: false,
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
      editando: false,
      cidades_ibge1: [],
      array_uploads: [],
      inserindo: false,
      user:null
    };
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("user"));
    this.currentEmpresa = JSON.parse(localStorage.getItem("currentEmpresa"));
    this.notification.clear;
    this.user = this.currentUser
  },
  computed: {
    /**
     * Total no. of records
     */
    isHide() {
      return !this.editando && !this.inserindo;
    },
    rows() {
      return this.empresas.length;
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    clonefoot() {
      return this.empresas.length > 10 ? true : false;
    },
  },
  mounted() {
    this.getData();
    this.get_list_certificados();
    this.cidades_ibge();

    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    ...authEasyMethods,
    ...notificationMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async exluir_empresa(objeto){
       console.log("mostrando objeto empresa exlcluri apenas para o usuario 1")
       console.log(objeto)
       console.log(this.user)
       try {
        let response = await http.delete(
          "/empresa/" + objeto.id + "?empresa_id=" + this.currentEmpresa.id,
          objeto
        );
        if (response) {
          if (response.status === 200) {
            //this.get_list_certificados();
            //this.getData();
            this.makeToast("warning", "Registro excluído");
          }
        }
      } catch (error) {
        //this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    async get_list_certificados() {
      //this.onLoader();
      try {
        let response = await http.get(
          "/nfe/getupload?empresa_id=" + this.currentEmpresa.id
        );
        if (response.status === 200) {
          this.array_uploads = response.data;
          console.log(this.pre_compra);
          //this.abr_certificado = true;
          // tempo para carregar a tela;
        } else {
          //this.array_uploads = []; // zera o array;
          this.makeToast("danger", "Erro ao carregar lista");
          //  this.offLoader();
        }
      } catch (error) {
        //this.offLoader();

        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error.response);
          }
        }
      }
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    async cidades_ibge() {
      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id
        // e  só consigo fazer o get por id ou estado ou nome ;
        let response = await http.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/distritos"
        );

        if (response.status === 200) {
          this.cidades_ibge1 = response.data;
          //  console.log(this.cidades_ibge1);
          // console.log(this.cidades_ibge1)

          // this.cidades_ibge1.forEach((cidade) => {

          //   console.log(cidade.municipio)

          // });
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            this.offLoader();
            break;
        }
      }
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none") {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block") {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    async getData() {
      this.onLoader();
      try {
        await http
          .get("empresa/list/" + this.currentUser.id)
          .then((res) => {
            this.empresas = res.data;
          })
          .finally(() => {
            setTimeout(() => this.offLoader(), 3000);
          });
      } catch (error) {
        this.offLoader();
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
      }
    },
    setEmpresaDetail(empresa) {
      this.currentEmpresa = empresa;
    },
    back() {
      this.getData();
      this.editando = false;
      this.inserindo = false;
    },
    Inseir() {
      console.log("estou em inserindo");
      this.inserindo = true;
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    async doPut(objeto) {
      let response = await http
        .put(
          "/empresa/" + objeto.id,
          objeto
        )
        .catch((error) => {
          this.error(error.response);
          this.makeToast("danger", error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.offLoader();
        this.makeToast("success", "Empresa alterado");
      }
    },
    async doPost(objeto) {
      console.log(objeto)
      try {
      let response = await http
        .post(
          "/empresa?empresa_id=" + this.currentEmpresa.id,
          objeto
        )
        console.log("mostrando oerro ")
        console.log(response)
        // .catch((error) => {
        //   this.error(error.response);
        //   console.log(error)
        //   this.makeToast("danger", error.response);
        // });
       

      if(response.status != undefined){
        
      
      if (response.status === 200) {
        this.getData();
        this.back();
        this.offLoader();
        this.makeToast("success", "Pedido incluído");
      }
    }
  }catch(error){
     console.log(error.response.data)
     this.makeToast("danger", error.response.data);
  }
    },
    edit(empresa) {
      console.log("esotu mostando a empresa selecionada");
      console.log(empresa);
      this.editando = !this.editando;
      // this.current_empresa = empresa;
      this.currentEmpresa = empresa;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              :variant="notification.type"
              class="mt-3"
              v-if="notification.message"
              show
              dismissible
              >{{ notification.message }}</b-alert
            >
            <div class="row">
              <div class="col-sm-12 col-md-6"></div>
              <div class="col-sm-12 col-md-6 text-md-right">
                <button class="btn btn-light" @click="back()">Voltar</button>
                <button
                  class="btn btn-light ml-2"
                  @click="Inseir()"
                  v-if="isHide"
                >
                  +Inserir
                </button>
              </div>
            </div>
          </div>
          <!-- @newTitle="changeHomeTitle" -->
          <div class="card-body">
            <Edit
              v-if="editando"
              @status="editando"
              @newTitle="changeHomeTitle"
              @back="back"
              :ibge="cidades_ibge1"
              :oldEmpresa="currentEmpresa"
              :currentEmpresa="currentEmpresa"
              :array_certificados="array_uploads"
              @doPut="doPut"
            />
          </div>
          <div v-if="inserindo">
            <Insert
              :ibge="cidades_ibge1"
              :oldEmpresa="currentEmpresa"
              :currentEmpresa="currentEmpresa"
              :array_certificados="array_uploads"
              @newTitle="changeHomeTitle"
              @back="back"
              @doPost="doPost"
            />
          </div>
          <div class="card-body" v-if="!editando && !inserindo">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Exibir&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Pesquisar:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Pesquisar..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div v-if="!editando" class="table-responsive mb-0">
              <b-table
                :items="empresas"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :hover="true"
                :foot-clone="clonefoot"
              >
                <template #cell(acoes)="row">
                  <!-- `data.value` is the value after formatted by the Formatter -->
                  <b-dropdown
                    v-bind:id="'dropdown-' + row.item.id"
                    class="m-md-2"
                  >
                    <template #button-content>
                      <i data-v-6289eca4="" class="bx ri-menu-line"></i>
                    </template>
                    <b-dropdown-item
                      v-b-modal.modal-empresa
                      @click="setEmpresaDetail(row.item)"
                      ><i class="bx ri-file-search-line"></i>
                      Visualizar</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-b-modal.modal-edit
                      @click="edit(row.item)"
                      ><i class="bx ri-file-search-line"></i>
                      Editar</b-dropdown-item
                    >
                    <b-dropdown-item
                      v-if="user.id == 1"
                      v-b-modal.modal-edit
                      @click="exluir_empresa(row.item)"
                      ><i class="bx ri-file-search-line"></i>
                      Excluir</b-dropdown-item
                    >
                  </b-dropdown>
                </template>
              </b-table>
            </div>
            <!-- End Table -->
            <!-- START Modal Empresa -->
            <b-modal
              hide-footer
              id="modal-empresa"
              size="xl"
              :title="currentEmpresa.apelido"
              title-class="font-18"
            >
              <Detail :empresa="currentEmpresa" />
            </b-modal>
            <!-- END Modal Empresa -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
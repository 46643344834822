<script>
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import { http } from "../../../helpers/easyindustriaapi/config";
import axios from "axios";

export default {
  page: {
    title: "Inserir Empresa",
    meta: [{ name: "description", content: appConfig.description }],
  },
  props: {
    currentEmpresa: {},
    oldEmpresa: { type: Object },
    ibge: { type: Array }, // não estou usand mais
    array_certificados: { type: Array },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      select_regime_trib: null,
      select_municipio: null,
      certificados: [],
      select_ambiente: null,
      select_emp_uf: null,
      abr_certificado: false,
      array_uploads: [],
      certificado: {
        razao_social: null,
      },
      certificado_bol: false,
      password: null,
      file: null,
      razao: null,
      uf: null,

      editando: false,
      //  empresa: {},
      titleBody: "Inserindo Empresa",
      list2_cfop: ["one", "two", "tree"],
      tipos: [
        {
          id: 1,
          nome: "producão",
        },
        {
          id: 2,
          nome: "homologação",
        },
      ],
      regimes: [
        {
          id: 1,
          nome: "Simples Nacional",
        },
        {
          id: 2,
          nome: "Simples Nacional - excesso de sublimite da receita bruta",
        },
        {
          id: 3,
          nome: "Regime Normal",
        },
      ],
      estados: [],
      diretorio: null,
      //file:null
      empresa: {},
      cidades_ibge1: [],
    };
  },
  created() {
    this.certificados = this.array_certificados;

    if (this.array_certificados.length > 0) {
      this.diretorio = this.array_certificados[0].nome;
    }
    //this.empresa = this.oldEmpresa;
    this.$emit("newTitle", this.titleBody);
    // this.convert_citys();
    this.estados_ibge();
  },
  mounted() {
    //this.setNewTitle();
    // console.log("mostrando empresa")
    // console.log(this.empresa)
  },
  methods: {
    setPost() {
      console.log("mostrando objeto empresa no setPost");
      console.log(this.empresa);
      this.$emit("doPost", this.empresa);
    },
    seleciona_regime_trib(objeto) {
      console.log("mostrando o regime");
      console.log(objeto);
      this.empresa.crt = objeto.id;
    },
    select_cidade_por_uf(objeto) {
      console.log("estou na uf metodo");
      this.empresa.uf = objeto.sigla;
      this.empresa.uf_id = objeto.id;
      this.empresa.pais_id = "1058";

      this.cidades_ibge(objeto);
    },
    seleciona_cod_nome_municipio(event) {
      this.empresa.cidade = event.nome;
      this.empresa.cidade_id = event.id;
    },
    async cidades_ibge(objeto) {
      let uf = objeto.sigla;
      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id
        // e  só consigo fazer o get por id ou estado ou nome ;
        let response = await http.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados/" +
            uf +
            "/municipios"
        );

        if (response.status === 200) {
          this.cidades_ibge1 = response.data;
          //console.log(this.cidades_ibge1);
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            //this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            ///this.offLoader();
            break;
          default:
            this.makeToast("danger", error.message);
            //this.offLoader();
            break;
        }
      }
    },
    seleciona_ambiente(event) {
      console.log("selecionando ambiente");
      this.empresa.uhomprod = event.id;
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      // console.log(this.file);

      // console.log(this.file.name);
    },
    async uploadFile() {
      if (this.certificados.length == 1) {
        this.makeToast("danger", "certificado Já existe");
      } else {
        console.log("estou aqui no upload ");
        const formData = new FormData();
        formData.append("file", this.file);
        formData.append("password", this.password);
        this.certificado.razao_social = this.currentEmpresa.razao;
        this.certificado.uf = this.currentEmpresa.uf;
        this.certificado.cnpj = this.currentEmpresa.cnpj;
        formData.append("razao", this.certificado.razao_social);
        formData.append("uf", this.certificado.uf);
        formData.append("cnpj", this.certificado.cnpj);
        formData.append("empresa_id", this.currentEmpresa.id);
        //console.log(formData)

        // for (let [key, value] of formData.entries()) {
        //   // Verifica se o valor é um objeto File
        //   if (value instanceof File) {
        //     console.log(`${value.name} ${key}`);
        //   }
        // }
        this.abr_certificado = false;
        try {
          const response = await http.post("/nfe/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.status == 200) {
            // this.get_list_certificados();

            this.makeToast("success", "upload feito");
          }
          setTimeout(() => {
            this.abr_certificado = true;
          }, 1000);

          console.log(response.data);
        } catch (error) {
          console.error("Erro no upload:", error);
        }
      } // fim else
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    handleFileChange(event) {
      // Atualiza a variável 'diretorio' com o nome do arquivo selecionado

      this.diretorio = event.target.files[0].name;
      this.empresa.nome_certificado = this.diretorio; // não precisa desses dois é so fazer um relacionamento com a table uploadas
      this.empresa.password = this.password;
      this.file = event.target.files[0];
      console.log(this.diretorio);
      console.log(this.currentEmpresa);
      console.log(this.password);
      //console.log(this.diretorio)
    },
    async post_enviar(event) {
      event.preventDefault();

      let certificado = this.file;
      certificado.empresa_id = this.currentEmpresa.id;
      certificado.user_id = this.currentUser.id;
      //console.log(banco);
      this.onLoader();

      try {
        let response = await http.post(
          "/certificado?empresa_id=" + this.currentEmpresa.id,
          certificado
        );
        if (response) {
          this.offLoader();
          if (response.status === 200) {
            this.getData();
            this.inserindo = false;
            this.makeToast("success", "certificado incluído");
          }
        }
      } catch (error) {
        this.offLoader();
        if (error.response) {
          if (error.response.status === 404) {
            this.makeToast("danger", "Destino URL não encontrado!");
          } else {
            this.makeToast("danger", error);
          }
        }
      }
    },
    convert_citys() {
      this.ibge.forEach((cidade) => {
        //console.log(cidade.municipio.microrregiao.mesorregiao.UF.nome)
        //this.estados.push(cidade.municipio.microrregiao.mesorregiao.UF)
        // if (!this.estados.includes(cidade.municipio.microrregiao.mesorregiao.UF.nome)){
        //   this.estados.push(cidade.municipio.microrregiao.mesorregiao.UF.nome)
        // }

        // if(cidade.municipio.microrregiao.mesorregiao.UF.nome
        //  != this.ibge[index+1]. municipio.microrregiao.mesorregiao.UF.nome ){
        //   this.estados.push(cidade.municipio.microrregiao.mesorregiao.UF)
        //  }
        this.adicionarSemDuplicatas(
          cidade.municipio.microrregiao.mesorregiao.UF
        );

        //this.estados.push(cidade.municipio.microrregiao.mesorregiao.UF)

        //console.log(cidade.municipio.microrregiao.mesorregiao.UF);
      });
    },
    adicionarSemDuplicatas(novoObjeto) {
      if (!this.estados.some((objeto) => objeto.nome === novoObjeto.nome)) {
        this.estados.push(novoObjeto);
      }
    },
    setNewTitle() {
      this.$emit("newTitle", "Alterar Empresa " + this.empresa.id);
    },
    // voltar () {
    //   this.inserindo = !this.inserindo;
    //   this.$emit('status', this.inserindo);
    //   this.$emit('voltar');
    // },
    // setAlterar () {
    //   this.inserindo = !this.inserindo;
    //   this.$emit("alterarEmpresa", this.empresa);
    // },
    setPut() {
      // chamo a funcãode atualização do index;
      this.$emit("doPut", this.empresa);
    },
    get_ibge() {},
    carregar_certificado() {
      // colocar o link do endpint aqui;
    },
    voltar() {
      this.$emit("back");
    },
    async estados_ibge() {
      try {
        // bug , aqui , pois não da para pesquisar por emepresa_id
        // e  só consigo fazer o get por id ou estado ou nome ;
        let response = await http.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
        );

        if (response.status === 200) {
          this.estados = response.data;
          // console.log("ibge");
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast(
              "danger",
              "Erro 406: " + messageErro.tipo
                ? messageErro.tiponome
                : messageErro.tpag
            );
            // this.offLoader();
            break;
          case 404:
            this.makeToast(
              "danger",
              "Erro 404: endpoint não encontrado ou servidor fora do ar"
            );
            // this.offLoader();
            break;

          default:
            this.makeToast("danger", error.message);
            // this.offLoader();
            break;
        }
      }
    },
    async search_new_cnpj(event) {
      console.log(event);
      try {
        //   if(typeof this.pessoa.pes_cpfcnpj == 'string'){
        //         if(this.pessoa.pes_cpfcnpj.length == 14){
        //           this.makeToast("success", 'tamnaho aceitavel');
        //         }else {
        //           this.makeToast("danger", 'tamnaho incorreto');
        //         }
        //   }
        // // this.loader.get = true;
        console.log("mostando cnpj");
        console.log(this.empresa.cnpj);
        let cnpj;
        if (typeof this.empresa.cnpj == "string") {
          if (
            this.empresa.cnpj.includes(".") &&
            this.empresa.cnpj.includes(".") &&
            this.empresa.cnpj.includes("/") &&
            this.empresa.cnpj.includes("-")
          ) {
            cnpj = this.empresa.cnpj
              .replace(".", "")
              .replace(".", "")
              .replace("/", "")
              .replace("-", "");
          } else {
            cnpj = this.empresa.cnpj;
          }
        }

        let response = await axios.get("https://api.cnpjs.dev/v1/" + cnpj);

        if (response.status === 200) {
          console.log(response.data);
          //let obj2 = response.data.data;
          // this.loader.get = false;
          setTimeout(() => {
            let obj = response.data;
            this.empresa.apelido = obj.nome_fantasia;
            this.empresa.razao = obj.razao_social;
            this.empresa.fantasia = obj.nome_fantasia; // obj.endereco.cep
            this.empresa.cep = obj.endereco.cep;
            this.empresa.numero = obj.endereco.numero;
            this.empresa.bairo = obj.endereco.bairro;
            this.empresa.complemento = obj.endereco.complemento;
            this.empresa.endereco =
              obj.endereco.tipo_logradouro + " " + obj.endereco.logradouro;
            this.empresa.fone = obj.telefone1;
            this.empresa.pais_id = "1058";
            this.empresa.uf = obj.endereco.uf;

            console.log(this.estados);
            let estado_obj = this.estados.find(
              (e) => e.sigla == obj.endereco.uf
            );
            //console.log(estado_obj)
            this.select_emp_uf = estado_obj;
            this.cidades_ibge(estado_obj);

            //obj.endereco.
            //  this.cidades_ibge1.
            //  this.pessoa.pais_id =  this.listPaises[0].id
            // let uf_selecionado = this.listUFs.find((uf)=>uf.uf == obj.endereco.uf)
            //  console.log(uf_selecionado)
            //  this.pessoa.estado_id =  uf_selecionado.id
            //  this.pessoa.pes_uf =  uf_selecionado.uf

            //this.getCidadesStart(this.pessoa.estado_id)

            // console.lgo(this.listCidades)
            //  pessoa.estado_id
            //  listUFs

            //tipo_logradouro ,  logradouro ,
            // this.pessoa.cidade_id = selectedCity.id;
            //  this.pessoa.pes_cidade
          }, 1000);
          setTimeout(() => {
            console.log("mosrando cidades dentro do time func ");
            console.log(this.cidades_ibge1);
            let municipio = response.data.endereco.municipio;
            console.log(municipio);

            let cidadade_objeto = this.cidades_ibge1.find(
              (c) =>
                c.nome
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase() ==
                municipio
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toUpperCase()
            );
            console.log("cidade objeto seleicionado");
            console.log(cidadade_objeto);
            this.select_municipio = cidadade_objeto;
            this.seleciona_cod_nome_municipio(cidadade_objeto);
          }, 1500);
        }
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 403) {
          this.error("Usuário não tem permissão!");
        }
        //  this.loader.get = false;
      }
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="accordion" role="tablist">
      <form role="form" class="form-horizontal">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido-dados-gerais variant="light"
              >Informações da empresa</b-button
            >
          </b-card-header>
          <b-collapse
            id="pedido-dados-gerais"
            visible
            accordion="pedido-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-3" id="desc_ID">
                  <div class="form-group">
                    <label for="nome_id" class="col-form-label">Nome</label>
                    <div class="col">
                      <input
                        class="form-control text-left"
                        type="text"
                        placeholder="Digite o Nome da Empresa"
                        id="nome_id"
                        v-model="empresa.apelido"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3">
                  <div class="form-group">
                    <label for="razao_id" class="col-form-label" id="CFOP_ID"
                      >Razão Social</label
                    >
                    <div class="col" id="razao_id">
                      <input
                        v-model="empresa.razao"
                        class="form-control text-left"
                        type="text"
                        placeholder="digite a razão social"
                        id="razao_id"
                      />
                      <!-- <div v-if="submitted && $v.pedido.CFOP.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.CFOP.required">
                          {{ $t("mesages.validation.required") }}</span>
                        </div> -->
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-4">
                  <div class="form-group" id="espaco1">
                    <label for="cnpj_id" class="col-form-label">CNPJ</label>
                    <div class="col">
                      <input
                        v-model="empresa.cnpj"
                        type="text"
                        class="form-control text-left"
                        placeholder="CNPJ"
                        id="nada"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-2">
                  <label for="cnpj_id">&nbsp;</label>
                  <div class="col">
                    <button
                      class="btn btn-primary"
                      @click.prevent="search_new_cnpj($event)"
                    >
                      Pesquisar
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group" id="espaco">
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="endereco" class="col-form-label"
                      >Endereço
                    </label>
                    <div class="col">
                      <input
                        v-model="empresa.endereco"
                        class="form-control text-left"
                        placeholder="Digite o endereço"
                        id="endereco_id"
                      />
                      <!-- <div v-if="submitted && $v.pedido.Unitario.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.Unitario.required">
                          {{ $t("mesages.validation.required") }}</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="bairro" class="col-form-label">Bairro</label>
                    <div class="col">
                      <input
                        v-model="empresa.bairo"
                        type="text"
                        class="form-control text-left"
                        placeholder="Digite o Bairro"
                        id="bairro_id"
                      />
                      <!-- <div v-if="submitted && $v.pedido.Desconto.$error" class="invalid-feedback">
                        <span v-if="!$v.pedido.Desconto.required">
                          {{ $t("mesages.validation.required") }}</span>
                      </div> -->
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="numero" class="col-form-label">Número</label>
                    <div class="col">
                      <input
                        v-model="empresa.numero"
                        type="text"
                        class="form-control text-left"
                        placeholder="Digite o Numero"
                        id="numero_id"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="form-group">
                    <label for="complemento" class="col-form-label"
                      >Complemento</label
                    >
                    <div class="col">
                      <input
                        v-model="empresa.complemento"
                        type="text"
                        class="form-control text-right"
                        placeholder="Digite o complemento"
                        id="complemento_id"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="telefone" class="col-form-label"
                      >Telefone</label
                    >
                    <div class="col">
                      <input
                        v-model="empresa.fone"
                        type="text"
                        class="form-control text-left"
                        placeholder="Digite o telefone"
                        id="telefone_id"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="cep" class="col-form-label">Cep</label>
                    <div class="col">
                      <input
                        v-model="empresa.cep"
                        type="text"
                        class="form-control text-left"
                        placeholder="Digite o Cep"
                        id="cep_id"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="insc_estadual" class="col-form-label"
                      >Inscrição Estadual</label
                    >
                    <div class="col">
                      <input
                        v-model="empresa.ie"
                        type="text"
                        class="form-control text-right"
                        placeholder="Digite a Inscrição estadual"
                        id="insc_estadual_id"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="UF" class="col-form-label">UF</label>
                    <div class="col">
                      <Multiselect
                        v-model="select_emp_uf"
                        :options="estados"
                        label="nome"
                        @select="select_cidade_por_uf($event)"
                      >
                      </Multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="UF" class="col-form-label">Municipio</label>
                    <div class="col">
                      <multiselect
                        v-model="select_municipio"
                        :options="cidades_ibge1"
                        label="nome"
                        @select="seleciona_cod_nome_municipio($event)"
                      >
                      </multiselect>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6 col-md-4">
                  <div class="form-group">
                    <label for="insc_municipal" class="col-form-label"
                      >Inscrição Municipal
                    </label>
                    <div class="col">
                      <input
                        v-model="empresa.im"
                        type="text"
                        class="form-control text-right"
                        placeholder="Digite a Inscrição municipal"
                        id="insc_municipal_id"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
          <!-- Menus de Impostos-->

          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pedido-complementares variant="light"
              >Dados NFE</b-button
            >
          </b-card-header>
          <b-collapse id="pedido-complementares">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <!-- <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="regime" class="col-form-label">Regime</label>
                    <div class="col">
                    </div>
                  </div>
                </div> -->
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="CSOSN" class="col-form-label"
                      >chave de acesso</label
                    >
                    <div class="col">
                      <input
                        type="text"
                        class="form-control text-left"
                        id="CSOSN"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="Origem" class="col-form-label"
                      >Token Sefaz</label
                    >
                    <div class="col">
                      <input
                        type="text"
                        class="form-control text-left"
                        id="CSOSN"
                        v-model="empresa.tokensefaz"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="tk_sefaz" class="col-form-label"
                      >ID Token Sefaz</label
                    >
                    <div class="col">
                      <input
                        v-model="empresa.idtokensefaz"
                        type="text"
                        class="form-control text-left"
                        id="tk_sefaz"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="base_icms" class="col-form-label"
                      >Tipo de Ambiente</label
                    >
                    <div class="col">
                      <Multiselect
                        @select="seleciona_ambiente($event)"
                        v-model="select_ambiente"
                        :options="tipos"
                        label="nome"
                      >
                      </Multiselect>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-12">
                  <div class="form-group">
                    <label for="Reg_trib" class="col-form-label"
                      >Regime tributario</label
                    >
                    <div class="col">
                      <Multiselect
                        v-model="select_regime_trib"
                        @select="seleciona_regime_trib($event)"
                        :options="regimes"
                        label="nome"
                      >
                      </Multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>

          <!--- ------------ -->

          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.pdv-ipi variant="light2"
              >Certficado</b-button
            >
          </b-card-header>
          <b-collapse id="pdv-ipi">
            <b-card-body>
              <div class="form-row form-group" role="group">
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="s_tributaria" class="col-form-label"
                      >Carregar Certificado no Computador</label
                    >
                    <div class="col">
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="customFile"
                          @change="handleFileChange($event)"
                        />

                        <label class="custom-file-label" for="customFile">{{
                          diretorio || "Selecionar o Certificado"
                        }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="cod_enq" class="col-form-label"
                      >Carregar Certificado No Banco</label
                    >
                    <div class="col">
                      <button
                        class="btn btn-light"
                        @click.prevent="uploadFile()"
                      >
                        Carregar
                      </button>
                      <button class="btn btn-light ml-2 mr-2">
                        Ler configurações
                      </button>
                      <button class="btn btn-light">Status</button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="form-group">
                    <label for="certificado" class="col-form-label"
                      >Senha</label
                    >
                    {{ password }}
                    <div class="col">
                      <input
                        type="text"
                        class="form-control text-left"
                        id="certificado_id"
                        v-model="password"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row form-group" role="group"></div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- engloba  todos-->
      </form>
      <!-- form engloba todos-->
    </div>
    <!--Engloba todos -->
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button class="btn btn-light" @click.prevent="setPost()">
          Inserir Empresa
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
      empresa: {
          type: Object,
      },
  },
  data () {
      return {
        loader: {
            get: false
        },
        listCidades: [],
        listUFs: [],
        listPaises: [],
    }
  },
  methods: {      
    voltar() {
        this.$emit('status', this.empresa);
        this.$emit('voltar');
    },
  },
}
</script>

<template>
    <div id="empresa">
        <div class="row flex-nowrap align-items-center px-4 pt-3 pb-2">
            <div class="col p-0" style="min-width: 0px;">
                <div class="d-flex align-items-center w-100">
                    <button class="btn btn-link"><i @click="$bvModal.hide('modal-empresa')" title="Voltar" class="fas fa-arrow-left mr-3"></i></button>
                    <i title="Empresa" class="mb-1 mr-1 fas fa-hospital-user h5"></i> 
                    {{ empresa.razao }}
                </div>
            </div>
            <div class="ml-3">
                <div class="d-flex pr-3 justify-content-end" style="opacity: 1;">
                    <div class="recordProgressionContainer">
                        <div class="btn-group">
                            <button title="Tipo" class="mw-160px btn btn-light height-36px d-flex btn align-items-center pl-3 py-0 bg-grey-hue-9 rounded pr-3">
                                <i aria-hidden="true" class="fa fa-stream mr-1"></i>
                                <div class="text-truncate">Empresa</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="container-fluid">
                <div class="colp-0 d-flex flex-row align-items-center flex-wrap">
                    <div class="pr-3" style="min-width: 0px;">
                        <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                            <div class="flex flex-column p-0 col-12">
                                <div class="label font-12 text-grey-2 p-0 d-flex">
                                    <label title="Identificador" name="assigned_empresa_id">Código<span class="text-warning mr-1">*</span></label>
                                </div>
                                <div class="d-flex align-items-center col-12 p-0 text-truncate">
                                    <span data-name="assigned_empresa_id" class="field-id">
                                        {{ empresa.id }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mr-14px border-right border-grey-8 mb-2">
                            <div class="mw-190px min-w-190">
                                <div class="d-flex flex-column mw-col-12">
                                    <label for="assigned_cpfcnpj">CPF/CNPJ<span class="text-warning mr-1">*</span></label>
                                </div>
                            </div>
                            <div class="d-flex align-items-center h-20 composite-value mw-col-10">
                                <span data-name="cnpj" v-bind:title="empresa.cnpj ? empresa.cnpj : 'Não configurado'" class="text-break text-truncate text-lighter-grey">
                                    {{ empresa.cnpj ? empresa.cnpj : 'Não configurado' }}
                                </span> 
                            </div>
                    </div>
                    <div class="pr-3" style="min-width: 0px;">
                            <div class="mw-190px min-w-190">
                                <div class="d-flex flex-column mw-col-12">
                                    <label for="assigned_ultianfe">Última NF-e nº</label>
                                </div>
                            </div>
                            <div class="d-flex align-items-center h-20 composite-value mw-col-10">
                                <span data-name="ultianfe" 
                                 v-bind:title="empresa.ultianfe ? empresa.ultianfe : 'Não configurado'" class="text-break text-truncate text-lighter-grey">
                                    {{ empresa.ultianfe ? empresa.ultianfe : 'Não configurado' }}
                                </span> 
                            <!---->
                            </div>
                    </div>
                    <div class="mr-14px border-right border-grey-8 mb-2">
                            <div class="mw-190px min-w-190">
                                <div class="d-flex flex-column mw-col-12">
                                    <label title="Homologação/Produção" name="assigned_empresa_uhomprod">Ambiente</label>
                                </div>
                            </div>
                            <div class="d-flex align-items-center h-20 composite-value mw-col-10">
                                <b-badge data-name="assigned_empresa_id" class="field-id" v-bind:variant="empresa.uhomprod == 2 ? 'primary' : 'warning'">
                                    {{ empresa.uhomprod == 2 ? 'Produção' : 'Homologação' }}
                                </b-badge>
                            </div>
                    </div>
                </div>

            </div>
            <div></div>
            <div class="container-fluid">
                <div class="colp-0 d-flex flex-row align-items-center flex-wrap">
                    <div class="pr-3" style="min-width: 0px;">
                        <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                            <div class="flex flex-column p-0 col-12">
                                <div class="label font-12 text-grey-2 p-0 d-flex">
                                    <label title="Identificador" name="assigned_empresa_ie">I. E.</label>
                                </div>
                                <div class="d-flex align-items-center col-12 p-0 text-truncate">
                                    <span data-name="assigned_ie" class="field-ie">
                                        {{ empresa.ie ? empresa.ie : 'Não configurado' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mr-14px border-right border-grey-8 mb-2">
                        <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                            <div class="flex flex-column p-0 col-12">
                                <div class="label font-12 text-grey-2 p-0 d-flex">
                                    <label title="Identificador" name="assigned_empresa_created_at">Criado em</label>
                                </div>
                                <div class="d-flex align-items-center col-12 p-0 text-truncate">
                                    <span data-name="assigned_created_at" class="field-created_at">
                                        {{ empresa.created_at ? empresa.created_at : 'Não configurado' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pr-3" style="min-width: 0px;">
                        <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                            <div class="flex flex-column p-0 col-12">
                                <div class="label font-12 text-grey-2 p-0 d-flex">
                                    <label title="Identificador" name="assigned_empresa_updated_at">Última alteração</label>
                                </div>
                                <div class="d-flex align-items-right col-12 p-0 text-truncate">
                                    <span data-name="assigned_updated_at" class="field-updated_at text-right">
                                        {{ empresa.updated_at ? empresa.updated_at : 'Não configurado' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mr-14px border-right border-grey-8 mb-2">
                        <div class="d-flex align-items-center border-right border-grey-8 text-truncate mb-2 mw-190px min-w-190">
                            <div class="flex flex-column p-0 col-12">
                                <div class="label font-12 text-grey-2 p-0 d-flex">
                                    <label title="Identificador" name="assigned_empresa_status">Situação</label>
                                </div>
                                <div class="d-flex align-items-center col-12 p-0 text-truncate">
                                    <b-badge data-name="assigned_status" class="field-status" v-bind:variant="parseInt(empresa.status) === -1 ? 'success' : 'danger'">
                                        {{ parseInt(empresa.status) === -1 ? 'Ativo' : 'Inativo' }}
                                    </b-badge>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="mb-4">
                <div class="p-3-5 bg-white empresa-endereco list-no-overflow c-text">
                    <div class="bg-white c-pointer text-grey-4">
                        <span><span class="text-warning mr-1">*</span> Endereço: <strong></strong>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>